import '../css/app.css';
import Alpine from 'alpinejs';
import { Alpine as AlpineType } from 'alpinejs';
import collapse from '@alpinejs/collapse';
import anchor from '@alpinejs/anchor';
import focus from '@alpinejs/focus';

import dropdown from './modules/dropdown';
import dialog from './modules/dialog';
import assignee_tooltip from './modules/assignee-tooltip';
import sort_table_columns from './modules/sort-table-columns';
declare global {
  var Alpine: AlpineType;
}

window.Alpine = Alpine;
// Plugins
Alpine.plugin(collapse);
Alpine.plugin(anchor);
Alpine.plugin(focus);

// Dropdown menus
Alpine.data('dropdown', dropdown);
Alpine.data('dialog', dialog);
Alpine.data('assignee_tooltip', assignee_tooltip);
Alpine.data('sort_table_columns', sort_table_columns);

Alpine.data('AssignDialog', () => ({
  open: false,
  form_data: {},
  dialog_title: '',
  dialog_sub_title: '',
  error: null,
  form_id: null,
  company_id: null,
  list_ui_element: null as HTMLElement | null,
  assignee_list: [],
  selected_assignees: [] as number[],
  in_release: false,
  release_input: null as HTMLInputElement,

  getAssigneeSortFunc() {
    const selectedAssignees = this.selected_assignees;
    return (a, b) => {
      const aAss = selectedAssignees.indexOf(a.id) != -1;
      const bAss = selectedAssignees.indexOf(b.id) != -1;
      if (aAss != bAss) {
        return !aAss
      }
      if (a.firstname == b.firstname) {
        return a.lastname < b.lastname
      }
      return a.firstname < b.firstname
    }
  },
  openDialog() {
    this.open = true;
    document.body.classList.add('overflow-clip');
  },

  closeDialog() {
    this.open = false;
    this.error = null;
    this.selected_assignees = [];
    this.assignee_list = [];
    document.body.classList.remove('overflow-clip');
  },

  renderNewAssigneeList(
    assignee_list: {
      id: number;
      firstname: string;
      lastname: string;
      email: string;
      status: string;
    }[]
  ) {
    const list_items = assignee_list.map((assignee: any) => {
      const id = assignee.id;
      const status = assignee.status ?? 0;
      const name = `${assignee.firstname} ${assignee.lastname}`;
      const initials = `${assignee.firstname[0]}${assignee.lastname[0]}`;
      return `<li class="AssigneeItem" data-status="${status}" data-id="${id}" data-fullname="${name}" @mouseenter="openTooltip($event)" @mouseleave="closeTooltip()">
            <span>
              ${initials}
            </span>
          </li>`;
    });

    if (this.list_ui_element) {
      this.list_ui_element.innerHTML = list_items.join('');
    }
  },
  saveAssigneesToRelease() {
    let frm = JSON.parse(this.release_input.value);
    const assigneeList = this.assignee_list.filter((assignee: { id: number }) =>
      this.selected_assignees.includes(assignee.id)
    ) ?? [];
    frm.assignments = assigneeList;
    this.release_input.value = JSON.stringify(frm);
    return assigneeList;
  },
  async saveAssigneesToForm() {
    if (this.in_release) {
      return this.saveAssigneesToRelease()
    }
    if (this.form_id === null) {
      return;
    }

    // get the id from the model value, and get the assignee object from the list
    const assignee_payload =
      this.assignee_list.filter((assignee: { id: number }) =>
        this.selected_assignees.includes(assignee.id)
      ) ?? [];

    return await fetch(`/api/disclosure/${this.form_id}/assignments`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(assignee_payload),
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error('Error:', error);
        return error;
      });
  },

  async fetchCompanyAssigneeList() {
    // If we're in a release, the list of assignees is set directly in the form,
    // so we fetch the complete list of available persons for the given company
    await fetch(`/api/person?company_id=${this.company_id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // check if the response is an error
        if (data.status === -1) {
          throw new Error(data.error);
        }
        // reset the error msg
        this.error = null;

        const all_available_assignees = data ?? [];
        const current_assignees = all_available_assignees.filter(x => this.selected_assignees.indexOf(x.id));
        const currentAssigneesMap = new Map(
          current_assignees.map(assignee => [assignee.id, assignee])
        );
        // combine the current assignees with the available assignees, remove dupplciates
        const combined_assignee_list = all_available_assignees.reduce(
          (acc: any[], assignee: any) => {
            if (!currentAssigneesMap.has(assignee.id)) {
              acc.push(assignee);
            }
            return acc;
          },
          current_assignees
        ).sort(this.getAssigneeSortFunc())
        this.assignee_list = combined_assignee_list;
      })
      .catch((error) => {
        this.error = error;
      });
  },

  async fetchAssigneesList() {
    if (this.form_id === null) {
      return;
    }
    await fetch(`/api/disclosure/${this.form_id}/assignments`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // check if the response is an error
        if (data.status === -1) {
          throw new Error(data.error);
        }
        // reset the error msg
        this.error = null;

        const current_assignees = data?.assignees ?? [];
        // set selected_assignees to the current assignees from backend
        this.selected_assignees = current_assignees.map(
          (assignee: any) => assignee.id
        );
        const all_available_assignees = data?.available ?? [];

        const currentAssigneesMap = new Map(
          current_assignees.map((assignee: any) => [assignee.id, assignee])
        );
        // combine the current assignees with the available assignees, remove dupplciates
        const combined_assignee_list = all_available_assignees.reduce(
          (acc: any[], assignee: any) => {
            if (!currentAssigneesMap.has(assignee.id)) {
              acc.push(assignee);
            }
            return acc;
          },
          current_assignees
        ).sort(this.getAssigneeSortFunc());

        this.assignee_list = combined_assignee_list;
      })
      .catch((error) => {
        this.error = error;
      });
  },

  assignDialogTrigger: {
    async ['@click']() {
      // Get data from the element
      let current_disclosure = this.$el.getAttribute('data-assign-dialog-form');
      this.in_release = this.$el.getAttribute('data-in-release');
      if (current_disclosure === null && this.in_release === null) {
        return;
      }

      if (this.in_release) {
        this.release_input = this.$el.closest("tr").querySelector("input[name='contents']");
        current_disclosure = this.release_input.value;
      }

      this.list_ui_element =
        this.$el.parentNode?.querySelector('ul.AssignedToList-List') ?? null;

      let form_data = JSON.parse(current_disclosure);
      if ('GroupBy' in form_data) {
        form_data = form_data.GroupBy;
      }

      this.form_id = form_data.id ?? false;
      this.company_id = form_data.company.id ?? false;

      // bail, we need either form id or company id
      if (!this.form_id && !this.company_id) {
        return;
      }

      if (this.in_release) {
        const assignments = form_data.assignments ?? [];
        this.selected_assignees = assignments.map(x => x.id);
        await this.fetchCompanyAssigneeList();
      } else {
        // fetch the assignees list for selection
        await this.fetchAssigneesList();
      }

      this.dialog_title = form_data.form.name ?? 'Assign Disclosure';
      this.dialog_sub_title =
        form_data.company.name ?? 'Company Name not found';

      this.openDialog();
    },
  },
  cancelButton: {
    ['@click']() {
      this.closeDialog();
    },
  },
  confirmButton: {
    async ['@click']() {
      const res = await this.saveAssigneesToForm();
      console.log('res', res);
      if (res.error) {
        this.error = res.error;
        return;
      }
      // update the assignee list UI
      this.renderNewAssigneeList(res);
      this.error = null;
      this.closeDialog();
    },
  },
  backdrop: {
    ['@click']() {
      this.closeDialog();
    },
  },
  assignDialog: {
    ['x-show']() {
      return this.open;
    },
    ['@keyup.escape']() {
      this.closeDialog();
    },
  },
}));

// Disclosure list with filter , for the /report-data view
const disclosure_list = document.querySelector(
  '[x-data="disclosureListViewHandler"]'
);
if (disclosure_list) {
  await import('./modules/disclosures/disclosure-list-with-filter').then(
    (module) => module.default(Alpine)
  );
}

const disclosure_single_view = document.querySelector(
  '[x-data="disclosureSingleViewHandler"]'
);
if (disclosure_single_view) {
  await import('./modules/disclosures/single-view').then((module) =>
    module.default(Alpine)
  );
}

Alpine.start();

// SIDEBAR TOGGLE
const sidebarMinimizerTrigger = document.querySelectorAll(
  '[data-minimize-sidebar]'
);

if (sidebarMinimizerTrigger) {
  sidebarMinimizerTrigger.forEach((trigger) => {
    trigger.addEventListener('click', function (e) {
      e.preventDefault();

      const icon = trigger.querySelector('span.material-symbols-rounded');
      if (icon) {
        icon.innerHTML =
          icon.innerHTML === 'chevron_right' ? 'close' : 'chevron_right';
      }
      document.body.toggleAttribute('sidebar-minimized');
    });
  });
}


